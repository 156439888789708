@import "__config";

body {
	color: #333;
	background: #fff;
}

.loginPage {
	height: 100vh;
	min-height: 450px;
	@media (--bp3) {
		display: flex;
		justify-content: center;
		&::before {
			content: "";
			display: block;
			width: 50%;
			height: 100%;
			background: $blue_light;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
		}
	}
	&__left {
		display: none;
		@media (--bp3) {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50%;
			max-width: 550px;
			background: $blue_light;
			& img {
				width: percentage(447.69 / 550);
				max-width: 448px;
			}
		}
	}
	&__right {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		@media (--bp3) {
			width: 50%;
			max-width: 550px;
		}
	}
}

.login {
	width: 100%;
	max-width: 365px;
	&__logo {
		line-height: 0;
		width: percentage(404 / $sp_ref);
		max-width: 202px;
		margin: 0 auto 45px;
		@media (--bp3) {
			width: 239px;
			margin: 0 auto 53px;
		}
	}
	&__form {
		width: percentage(710 / $sp_ref);
		margin: auto;
	}
	&__field {
		& + & {
			margin: 15px auto 0;
			@media (--min) {
				margin: 20px auto 0;
			}
		}
		& input {
			font-size: 16px;
			width: 100%;
			padding: 8px 10px;
			box-sizing: border-box;
			border: 1px solid #c4c4c4;
			border-radius: 5px;
			background-color: #fff;
			background-size: auto 20px;
			background-position: 15px center;
			background-repeat: no-repeat;
			@media (--bp3) {
				padding: 11px;
				background-size: auto 23px;
				background-position: 20px center;
			}
		}
		& ._user:placeholder-shown {
			background-image: url(../img/ph_mail.svg);
		}
		& ._pass:placeholder-shown {
			background-image: url(../img/ph_pass.svg);
		}
		& ._error {
			color: $red_1;
			font-size: 14px;
			margin: 0 0 10px;
		}
	}
	&__submit {
		margin: 15px 0 0;
		@media (--bp3) {
			margin: 20px 0 0;
		}
		& input {
			appearance: none;
			font-size: 16px;
			color: #fff;
			width: 100%;
			border-radius: 5px;
			border: none;
			background: $site_color;
			padding: 9px;
			@media (--bp3) {
				padding: 12px;
			}
		}
	}
	&__keep {
		font-weight: 400;
		text-align: center;
		margin: 1em 0;
		@media (--bp3) {
			margin: 24px 0 0;
		}
		& input[type="checkbox"] {
			margin: 0 5px 0 0;
		}
	}
	&__links {
		font-weight: 400;
		text-align: center;
		margin: 35px 0 0;
		@media (--bp3) {
			margin: 45px 0 0;
		}
	}
	&__link {
		display: inline-block;
		margin: 0 1em;
		& a {
			text-decoration: underline;
		}
	}
}
